import { get as _get, set as _set } from "lodash";

export default class Storage
{
    static TOOL_KEY: Readonly<string> = "events";

    /**
     * Imposta valore dato in oggetto [this.TOOL_KEY] in localStorage.
     *
     * @param key Chiave dove salvare valore dato (chiave oggetto separata da ".")
     * @param value Valore da impostare.
     */
    static set(key: string, value: any|any[]): void
    {
        const storage = this.getStorage(); // storage applicazione
        _set(storage, key, value); // set [value] passato a [key] data

        // set in localStorage
        localStorage.setItem(this.TOOL_KEY, JSON.stringify(storage));
    }

    /**
     * GET dato richiesto, salvato in oggetto [this.TOOL_KEY] in localStorage.
     *
     * @param key Chiave dato interessato.
     * @returns Dato richiesto.
     */
    static get(key: string): any
    {
        const storage = this.getStorage(); // storage applicazione
        return _get(storage, key);
    }

    static remove(key: string|undefined = this.TOOL_KEY): void
    {
        // rimozione di tutto il localStorage
        localStorage.removeItem(key);
    }

    /**
     * GET storage a chiave data.
     *
     * @param name Nome usato come chiave in localStorage.
     * @returns Storage richiesto.
     */
    private static getStorage(name = this.TOOL_KEY): Record<string, any>
    {
        const storage = localStorage[name];

        if(storage !== undefined) return JSON.parse(storage); // storage trovato
        return {}; // storage non definito
    }
}