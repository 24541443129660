import toastr from "./_imports/toastr";
import i18n from "./lang";
import TweenMax from "gsap/TweenMaxBase";
import { Elastic } from "gsap/EasePack";
import CSSPlugin from "gsap/CSSPlugin";
import Swal from "sweetalert2";

// options BLOCK-UI
export const block_options = { overlayColor: "#FFF", opacity: 0.9 };
export const block_primary_modal = { overlayColor: "#302f2f", opacity: 0.9 };

const _gsap = [CSSPlugin];

export const Toast = Swal.mixin(
{
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    onOpen: toast => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
});

export function catchDefaultError(error) {
    switch (error.status) {
        case 401: // Sessione scaduta
        case 419: // Token CSRF non valido
            // Questo errore viene già gestito tramite l'handler ajaxError
            return null;
        case 500:
            toastr.error(i18n("Internal server error"));
            return null;
        default:
            return error;
    }
}

/**
 * Mostra un elemento passando il suo selettore
 *
 * @param selector Elemento da mostrare.
 * @param display Proprietà CSS "display" da utilizzare per mostrare (DEFAULT = "block").
 *
 * @returns Promise<void>
 */
export function showAnimation(JQueryElement: JQuery<HTMLElement>, display = "block"): Promise<void>
{
    return new Promise(resolve =>
    {
        TweenMax.fromTo(
            JQueryElement,
            0.5,
            {
                autoAlpha: 0,
                display: display,
                scale: 0.9,
            },
            {
                autoAlpha: 1,
                scale: 1,
                ease: Elastic.easeOut.config(0.3, 0.25),
                onComplete()
                {
                    JQueryElement.css("transform", "none");
                    resolve();
                },
            },
            0.5,
        );
    });
}

/**
 * Nasconde un elemento passando il suo selettore
 */
export function hideAnimation(JQueryElement: JQuery<HTMLElement>): Promise<void>
{
    return new Promise(resolve =>
    {
        TweenMax.to(JQueryElement, 0.3,
            {
            autoAlpha: 0,
            scale: 0.8,
            ease: Elastic.easeOut.config(0.3, 0.25),
            display: "none",
            onComplete()
            {
                resolve();
                JQueryElement.css("transform", "none");
            },
        });
    });
}

/**
 * Cerca "FAIL_message" in "FAIL_messages" e restituisce il relativo testo, altrimenti "default_text".
 *
 * @param FAIL_message Messaggi di errore restituito da AJAX.
 * @param FAIL_messages Array CHIAVE->VALORE con STRINGA_ERRORE->TESTO_DA_MOSTRARE.
 * @param default_text Messaggio da restituire se "FAIL_message" non è stata trovata in "FAIL_messages".
 *
 * @returns Messaggio di errore.
 */
export function getFAILMessage(FAIL_message: string, FAIL_messages: Record<string, string>, default_text: string): string
{
    let text = ""; // testo da restituire

    // cerca in "FAIL_messages" "FAIL_message"
    for (const option_FAIL_message in FAIL_messages) if (option_FAIL_message === FAIL_message) text = FAIL_messages[option_FAIL_message];

    // testo se trovato, altrimenti "default_text"
    return text === "" ? default_text : text;
}

/**
 * Data una lista di oggetti restituisce un'array formattato per la funzione "setList" di jQuery Form Repeater.
 *
 * @param items Lista da utilizzare per la creazione del form repeater.
 * @param params Parametri da prendere nella lista, da utilizzare come "name" delle input del form repeater.
 *
 * @returns Array da passare alla funzione "setList" del form repeater.
 */
export function getRepeaterList(items: Record<string, any>[], params: string[]): Record<string, any>[]
{
    const ret: Record<string, any>[] = []; // un oggetto per ogni input del blocco

    items.forEach(item =>
    {
        const row: Record<string, any> = {};

        params.forEach(param => (row[param] = item[param]));
        ret.push(row);
    });

    return ret;
}

export function delay(ms: number): Promise<void>
{
    return new Promise<void>(resolve => {
        setTimeout(resolve, ms);
    });
}

export function handleDefaultError(error: any): boolean
{
    switch (error.status) {
        case 401: // Sessione scaduta
        case 419: // Token CSRF non valido
            Toast.fire({ type: "error", title: i18n("Expired session, log in again") });
            // Ritardo di 1500ms per dare all'utente il tempo di leggere
            // il messaggio prima del redirect
            delay(1500).then(() => window.location.reload());
            return true;
    }
    return false;
}

export function handleError(error: any): void
{
    switch (error.status) {
        case 403:
            Toast.fire({ type: "error", title: i18n("You don't have permission") });
            break;
    }
}

// formattazione name per ricerca in oggetto (con "_.get()" di LODHASH)
// ES. da "name[other][other_2]" in "name.other.other_2"
export function getDottedInputName(name: string): string
{
    if(name[name.length -1] === "]") name = name.substr(0, name.length -1);
    return name.split("[").join(".").split("]").join("");
}

/**
 * Restituisce il markup per renderizzare una serie di badge
 * a partire da una collection, mostrando all'interno del badge, per ogni item, il valore
 * dell'attributo interessato.
 *
 * @param {Record<string, any>[]}           collection Lista dalla quale comporre i badge.
 * @param {string}                          attribute  Attributo il cui valore deve essere mostrato all'interno di ciascun badge.
 * @param {number | undefined}              max        Numero massimo di badge da mostrare. Se non definito, mostra tutti, altrimenti mostra i primi e mette i restanti in un popover.
 * @param {Record<string, any> | undefined} options
 *
 * @returns {string}
 */
export function badgeListHTML(collection: Record<string, any>[], attribute: string|undefined, max: number | undefined = undefined/* , options: Record<string, any> | undefined = undefined */): string
{
    // se non specifico un cap o il cap è oltre il numero di elementi da visualizzare, renderizza tutto
    if (max === undefined || max >= collection.length)
        return collection.map((el: Record<string, any>) => {
            return `<span class="kt-badge kt-badge--inline kt-badge--unified-dark mt-1 mb-1 text-nowrap">
                ${attribute === undefined ? el : el[attribute]}
            </span>`
        }).join(" ");


    // badge subito visibili
    let markup: string = collection.slice(0, max).map((el: Record<string, any>) => {
        return `<span class="kt-badge kt-badge--inline kt-badge--unified-dark mt-1 mb-1 text-nowrap">${attribute === undefined ? el : el[attribute]}</span>`;
    }).join(" ");

    const left: Record<string, any>[] = collection.slice(max);
    let leftHTML: string = left.map((el: Record<string, any>) => `<li>${attribute === undefined ? el : el[attribute]}</li>`).join("");
    leftHTML = `<ul>${leftHTML}</ul>`;

    // aggiungi markup per visualizzare più badges.
    markup += `
        <a tabindex="0" data-toggle="popover" data-trigger="hover" data-html="true" data-placement="right" data-content="${leftHTML}">
            <span class="kt-badge kt-badge--inline kt-badge--unified-info kt-font-bold mt-1 mb-1 text-nowrap">
                +${left.length}
            </span>
        </a>`;

    return markup;
}